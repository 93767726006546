<template>
    <div style="margin:-12px;width:100vw;">
        <div v-if="product != null">
            <!-- app bar -->
            <top-nav title="Producto">
                <template v-slot:actions>
                    <v-btn icon @click="saveProduct"><v-icon>mdi-floppy</v-icon></v-btn>
                </template>
            </top-nav>

            <v-card flat tile color="grey lighten-5">
                <v-card-text>
                    <!-- el product type y state lo voy a manejar de otra manera, no con fields -->
                    <v-slide-group show-arrows class="mb-3">
                        <v-slide-item>
                            <v-chip class="mr-2" small :color="product.state == 'draft' ? 'warning' : 'success'" @click="changeState">
                                {{ product.state }}
                            </v-chip>
                        </v-slide-item>

                        <v-slide-item>
                            <v-chip class="mr-2" small @click="productTypeDialog = true">
                                {{ product.type }}
                            </v-chip>
                        </v-slide-item>
                    </v-slide-group>

                    <v-divider class="mb-3"></v-divider>

                    <!-- GENERAL INFORMATION -->
                    <v-card-subtitle class="pl-0 subtitle-1">General Information</v-card-subtitle>

                    <v-text-field v-model="product.name" label="Name" outlined background-color="white" @input="generateSlug"></v-text-field>

                    <v-text-field v-model="product.slug" label="Slug" outlined background-color="white"></v-text-field>
                    
                    <!-- <span class="caption">Short description</span>
                    <vue-editor v-model="product.shortDescription" :editorToolbar="editorToolbar" class="mb-5"></vue-editor> -->

                    <span class="caption">Description</span>
                    <vue-editor v-model="product.description" :editorToolbar="editorToolbar" class="mb-5"></vue-editor>

                    <v-text-field v-model="product.categories" label="Categories" outlined background-color="white"></v-text-field>

                    <v-select v-model="product.trainingType" :items="trainingTypes" label="Training Type" outlined background-color="white"></v-select>
                    <v-row no-gutters>
                        <v-col cols="10">
                            Generate Subscription
                        </v-col>
                        <v-col cols="2" justify-end>
                            <v-switch v-model="product.generateSubscription" class="mt-0"></v-switch>
                        </v-col>
                    </v-row>

                    <!-- Subscription details -->
                    <div v-show="product.generateSubscription">
                        <v-card-subtitle class="pl-0 subtitle-1">Subscription</v-card-subtitle>

                        <v-text-field v-model="product.subscriptionSessions" label="Sessions" outlined background-color="white"
                            hint="How many sessions this includes?" persistent-hint></v-text-field>
                        <v-select v-model="product.subscriptionFrequency" :items="frequencies" label="Subscription Frequency" outlined 
                            hint="How frequently should the subscription be renewed?" persistent-hint background-color="white"></v-select>
                    </div>

                    <!-- PRICE & SALE -->
                    <v-card-subtitle class="pl-0 subtitle-1">Price & Sale</v-card-subtitle>

                    <v-text-field v-model="product.price" label="Regular Price" outlined background-color="white"></v-text-field>
                    <v-row no-gutters>
                        <v-col cols="4">
                            <v-text-field v-model="product.salePrice" label="Sale Price" outlined background-color="white" class="txt-left"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-dialog ref="saleFrom" v-model="saleFromDialog" width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="product.saleFrom" label="Sale From" outlined background-color="white" class="txt-middle" v-bind="attrs"
                                        v-on="on" readonly></v-text-field>
                                </template>

                                <v-card>
                                    <div class="subtitle-1 text-center">Sale from...</div>
                                    <v-date-picker v-model="product.saleFrom" no-title @input="saleFromDialog = false" scrollable></v-date-picker>
                                    <v-btn depressed block tile color="secondary" @click="product.saleFrom = null; saleFromDialog = false">Clear</v-btn>
                                </v-card>
                            </v-dialog>
                        </v-col>
                        <v-col cols="4">
                            <v-dialog ref="saleFrom" v-model="saleToDialog" width="290px">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-text-field v-model="product.saleTo" label="Sale To" outlined background-color="white" class="txt-right" v-bind="attrs"
                                        v-on="on" readonly></v-text-field>
                                </template>

                                <v-card>
                                    <div class="subtitle-1 text-center">Sale to...</div>
                                    <v-date-picker v-model="product.saleTo" no-title @input="saleToDialog = false" scrollable></v-date-picker>
                                    <v-btn depressed block tile color="secondary" @click="product.saleTo = null; saleToDialog = false">Clear</v-btn>
                                </v-card>
                            </v-dialog>
                        </v-col>
                    </v-row>

                    <v-row no-gutters>
                        <v-col cols="10">
                            Is taxable
                        </v-col>
                        <v-col cols="2" justify-end>
                            <v-switch v-model="product.isTaxable" class="mt-0"></v-switch>
                        </v-col>
                    </v-row>

                    <!-- STOCK -->
                    <div v-show="product.type != 'Virtual'">
                        <v-card-subtitle class="px-0 pt-0 subtitle-1">Stock</v-card-subtitle>
                        
                        <v-row no-gutters>
                            <v-col cols="10">
                                Manage stock for this product
                            </v-col>
                            <v-col cols="2" justify-end>
                                <v-switch v-model="product.manageStock" class="mt-0"></v-switch>
                            </v-col>
                        </v-row>
                        <v-text-field v-model="product.stock" label="Stock Qty" outlined background-color="white" :disabled="!product.manageStock"></v-text-field>

                        <div v-show="['Presencial', 'Online', 'OneOnOne'].includes(product.trainingType)">
                            <v-card-subtitle class="pl-0 subtitle-1">Rules</v-card-subtitle>

                            <span class="caption">General Rules</span>
                            <vue-editor v-model="product.generalRules" :editorToolbar="editorToolbar" class="mb-5"></vue-editor>

                            <span class="caption">Cancelation Rules</span>
                            <vue-editor v-model="product.cancelationRules" :editorToolbar="editorToolbar" class="mb-5"></vue-editor>

                            <span class="caption">Strike Rules</span>
                            <vue-editor v-model="product.strikeRules" :editorToolbar="editorToolbar" class="mb-5"></vue-editor>
                        </div>
                    </div>

                    <!-- SHIPPING -->
                    <div v-show="product.type != 'Virtual'">
                        <v-card-subtitle class="px-0 pt-0 subtitle-1">Shipping</v-card-subtitle>

                        <span class="caption">Dimensions</span>
                        <v-row no-gutters class="mt-2">
                            <v-col cols="4">
                                <v-text-field v-model="product.length" label="Length" outlined background-color="white" class="txt-left"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="product.width" label="Width" outlined background-color="white" class="txt-middle"></v-text-field>
                            </v-col>
                            <v-col cols="4">
                                <v-text-field v-model="product.height" label="Height" outlined background-color="white" class="txt-right"></v-text-field>
                            </v-col>
                        </v-row>
                        
                        <v-text-field v-model="product.weight" label="Weight" outlined background-color="white"></v-text-field>
                    </div>

                    <!-- IMAGES -->
                    <v-card-subtitle class="px-0 pt-0 subtitle-1">Images</v-card-subtitle>

                    <image-picker v-model="images" primary-image></image-picker>

                    <!-- RESOURCES -->
                    <v-card-subtitle class="px-0 pt-5 subtitle-1" v-show="product.trainingType == 'online'">Resources</v-card-subtitle>

                    <!-- <v-text-field v-model="product.resources" label="Resources" outlined></v-text-field> -->
                    <resource-picker v-model="resources" v-show="product.trainingType == 'online'"></resource-picker>
                    <!-- <v-text-field v-model="product.tags" label="Tags" outlined></v-text-field> -->
                </v-card-text>
            </v-card>

            <v-dialog v-model="productTypeDialog" width="300">
                <v-card>
                    <v-list three-line>
                        <v-list-item-group value="selectedProductType" color="primary">
                            <v-list-item @click="product.type = 'simple'">
                                <v-list-item-content>
                                    <v-list-item-title>Simple</v-list-item-title>
                                    <v-list-item-subtitle>Represent a physical product that can be shipped.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                            <v-list-item @click="product.type = 'virtual'">
                                <v-list-item-content>
                                    <v-list-item-title>Virtual</v-list-item-title>
                                    <v-list-item-subtitle>Represent a digital product.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </v-dialog>

            <v-overlay :value="saving">
                <v-card flat color="white" min-width="200">
                    <v-card-text>
                        <div class="subtitle-1 grey--text text--darken-2 text-center mb-2">Saving...</div>
                        <v-progress-linear color="primary" indeterminate rounded height="6"></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-overlay>

            <!-- bottom nav -->
        </div>

        <v-skeleton-loader class="mx-auto" type="article, article, article, actions" v-else></v-skeleton-loader>
    </div>
</template>

<script>
import TopNav from '@/components/admin/TopNav';
import { PetuProduct } from '@/classes/product';
import { _st } from '@/softech';
import { VueEditor } from 'vue2-editor';
import ImagePicker from '@/components/ImagePicker.vue';
import ResourcePicker from '@/components/ResourcePicker.vue';
import { State, ProductType } from '@/models';
import slugify from '@sindresorhus/slugify';

export default {
    props: {
        productId: { type: String, default: '' },
    },
    data() {
        return {
            product             : null,
            images              : [],
            resources           : [],

            saleFromDialog      : false,
            saleToDialog        : false,
            saving              : false,
            productTypeDialog   : false,

            trainingTypes       : [
                { text: 'N/A', value: 'na' },
                { text: 'Presencial', value: 'presencial' },
                { text: 'Online', value: 'online' },
                { text: 'One on One', value: 'one on one' }
            ],
            frequencies         : [
                { text: 'Weekly', value: 'weekly' },
                { text: 'Monthly', value: 'monthly' },
                { text: 'Annually', value: 'annually' },
            ],

            editorToolbar       : [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }], 
                ["bold", "italic", "underline"], 
                [{ align: ["", "center", "right", "justify"]}],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                ["link"],
                [{ color: [] }, { background: [] }],
            ],
        }
    },
    computed: {
        selectedProductType() {
            if( this.product.type == ProductType.SIMPLE ) 
                return 0;

            return 1;
        },
    },
    async mounted() {
        // load product
        let product = new PetuProduct();

        if( _st.isNUE( this.productId ) ) {
            this.product = product.data;
            return;
        }

        await product.load( this.productId );
        this.product = product.data;

        await product.getImagesSrc();
        this.images = product.srcImages;

        await product.loadResources();
        await product.getResourcesSrc();
        this.resources = product.srcResources;
    },
    methods: {
        async saveProduct() {
            let product = new PetuProduct( this.product );
            product.srcImages = this.images;
            product.srcResources = this.resources;
            const isNew = _st.isNU( product.data.id ) || _st.isEmpty( product.data.id );

            this.saving = true;
            await product.save();

            if( isNew ) {
                this.$router.push( '/secure/product/' + product.data.id );
                return;
            }

            this.saving = false;
            
            this.product = product.data;

            await product.getImagesSrc();
            this.images = product.srcImages;

            await product.getResourcesSrc();
            this.resources = product.srcResources;
        },
        changeState() {
            if( this.product.state == State.DRAFT.toLowerCase() )
                this.product.state = State.PUBLISHED.toLowerCase();
            else
                this.product.state = State.DRAFT.toLowerCase();
        },
        generateSlug() {
            // this.product.slug = this.product.name.toLowerCase().replace(/\s/g, '-').replace(/[^\w-]+/g, '');
            this.product.slug = slugify( this.product.name );
        }
    },
    components: {
        TopNav,
        VueEditor,
        ImagePicker,
        ResourcePicker
    }
}
</script>

<style lang="scss">
.quillWrapper .ql-toolbar {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    background-color: #efefef;
}
.quillWrapper .ql-container {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
}
</style>

<style lang="scss" scoped>
.txt-left {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.txt-middle {
    border-radius: 0;
}
.txt-right {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
</style>