<template>
    <div>
        <v-alert type="error" :value="errorMessage.length > 0">{{ errorMessage }}</v-alert>
        <v-list>
            <template v-for="(img, ix) in images">
                <v-list-item :two-line="!img.primary" :three-line="img.primary" :key="ix" @click="setPrimary(ix)" class="px-1">
                    <v-list-item-avatar tile>
                        <v-img :src="img.imgUrl" max-height="110px" min-height="110px" position="top center" class="rounded"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ img.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ img.size }}</v-list-item-subtitle>
                        <v-list-item-subtitle v-if="img.primary"><v-chip color="secondary" small label>Primary image</v-chip></v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon @click="removeImg(ix)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>

                <!-- <v-col cols="6" md="4" lg="3" class="pa-1" :key="ix">
                    <v-img :src="img.imgUrl" max-height="110px" min-height="110px" position="top center" class="rounded" @click="setPrimary(ix)">
                        <div class="fill-height top-gradient" style="position:relative;">
                            <v-row>
                                <v-col cols="9" class="mx-0 pt-1">
                                    <div class="imgTitle white--text ma-0 pl-2">{{ img.name.length > 12 ? img.name.substring(0, 12) + '...' : img.name }}</div>
                                    <div class="imgSize grey--text text--lighten-2 ma-0 pl-2">{{ img.size }}</div>
                                </v-col>
                                <v-col cols="3" class="mx-0 pt-1">
                                    <v-btn fab small flat color="grey darken-1" class="removeBtn" @click="removeImg(ix)"><v-icon color="white">mdi-close</v-icon></v-btn>
                                </v-col>
                            </v-row>
                            <div style="bottom:0;position:absolute;width:100%;opacity:0.9" class="blue white--text text-center" v-if="img.primary">PRIMARY</div>
                        </div>
                    </v-img>
                </v-col> -->
            </template>

            <v-btn block depressed color="primary" @click="showSelectDialog" style="text-transform: none;">Add new</v-btn>
            <!-- <v-col cols="6" md="4" lg="3" class="pa-1">
                <div class="rounded grey lighten-4" style="min-height: 110px; display:flex;justify-content:center;cursor:pointer;" @click="showSelectDialog">
                    <v-icon size="65">mdi-plus-circle-outline</v-icon>
                </div>
            </v-col> -->
        </v-list>
        <v-btn depressed block color="error" v-if="clearable" v-show="images.length > 0" @click="removeDialog = true" style="text-transform: none;">Remove all</v-btn>
        <input type="file" :accept="accept" :multiple="multiple" ref="picker" @change="updateImages" class="hidden" />

        <v-dialog v-model="removeDialog" width="300">
            <v-card color="white">
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to remove all selected images?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed @click="removeDialog = false" style="text-transform:none;">Cancel</v-btn>
                    <v-btn depressed color="error" style="text-transform:none;" @click="clearImages">Remove</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { _st } from '../softech';
export default {
    props: {
        // allow selecting more than one image at a time
        multiple: {
            type: Boolean,
            default: true
        },
        // type of images allowed
        accept: {
            type: String,
            default: 'image/png, image/jpeg'
        },
        // max images allowed
        max: {
            type: Number,
            default: 10
        },
        // determine if the images can be reordered
        ordered: {
            type: Boolean,
            default: false,
        },
        // this propery determine if the user can select the main image of a gallery
        primaryImage: { 
            type: Boolean,
            default: false
        },
        // show button to clear all images
        clearable: {
            type: Boolean,
            default: true
        },
        // object to be shared of selected images
        value: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    data() {
        return {
            images: [],
            errorMessage: '',
            removeDialog: false,
        }
    },
    computed: {
        columns: function() {
            if( this.itemQty >= 4 )
                return 3;
            else if( this.itemQty == 3 )
                return 4;
            else if( this.itemQty == 2 )
                return 6;

            return 12;
        },
        itemQty: function() {
            return this.items.length;
        },
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        }
    },
    methods: {
        init() {
            this.images = _st.isNU( this.value ) ? [] : this.value;
        },
        updateImages() {
            this.errorMessage = '';

            if( this.images.length + this.$refs.picker.files.length > this.max ) {
                this.errorMessage = 'Only ' + this.max + ' image(s) allowed';
                console.log(this.errorMessage);
                return;
            }

            for( let img of this.$refs.picker.files ) {
                this.encodeImageFileAsURL(img);
            }

            this.$emit( 'input', this.images );
        },
        encodeImageFileAsURL(img) {
            var reader = new FileReader();
            reader.onloadend = () => {
                this.images.push({
                    name    : img.name,
                    size    : ( img.size / 1024 / 1024 ) < 1 ? Math.round( img.size / 1024 ) + ' KB' : ( img.size / 1024 / 1024 ).toFixed(2) + ' MB',
                    imgUrl  : reader.result,
                    data    : img,
                    primary : false,
                    order   : 0,
                    isNew   : true,
                });
            }
            reader.readAsDataURL(img);
        },
        removeImg(index) {
            this.errorMessage = '';
            this.images.splice(index, 1);
            this.$emit('input', this.images);
        },
        clearImages() {
            this.images = [];
            this.$emit('input', this.images);
            this.removeDialog = false;
        },
        setPrimary(index) {
            if( !this.primaryImage )
                return;

            this.images.forEach(img => {
                img.primary = false;
            });

            this.images[index].primary = true;
            this.$emit('input', this.images);
        },
        showSelectDialog() {
            this.$refs.picker.click();
        }
    }
}
</script>

<style lang="scss" scoped>
.hidden {
    display: none;
}
</style>