<template>
    <div>
        <v-alert type="error" :value="errorMessage.length > 0">{{ errorMessage }}</v-alert>
        <v-list>
            <template v-for="(rsc, ix) in resources">
                <v-list-item :two-line="!rsc.primary" :three-line="rsc.primary" :key="ix" class="px-1" @click="editResource( rsc )">
                    <v-list-item-avatar tile>
                        <v-img :src="rsc.imgUrl" max-height="110px" min-height="110px" position="top center" class="rounded"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ rsc.name }}</v-list-item-title>
                        <v-list-item-subtitle>{{ rsc.description }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action>
                        <v-btn icon @click.stop="removeResource(ix)"><v-icon>mdi-close</v-icon></v-btn>
                    </v-list-item-action>
                </v-list-item>
            </template>

            <v-btn block depressed color="primary" @click="resourceDialog = true" style="text-transform: none;">Add new</v-btn>
        </v-list>
        <input type="file" :accept="resourceObj.type == 'video' ? acceptVideo : acceptImage" multiple="false" ref="picker" @change="updateResource" class="hidden" />

        <!-- add new resource dialog -->
        <v-dialog v-model="resourceDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card tile color="grey lighten-5">
                <v-toolbar color="primary" dark flat fixed>
                    <v-btn icon @click="closeForm"><v-icon>mdi-close</v-icon></v-btn>

                    Configure resource

                    <v-spacer></v-spacer>

                    <v-btn icon @click="addResource"><v-icon>mdi-floppy</v-icon></v-btn>
                </v-toolbar>
                <v-card-text class="pt-4">
                    <v-form ref="form">
                        <v-text-field v-model="resourceObj.name" label="Name" :rules="nameRules" outlined background-color="white"></v-text-field>
                        <v-select v-model="resourceObj.type" :items="resourcesTypes" label="Type" outlined background-color="white"></v-select>

                        <div class="resourceWrapper" v-show="resourceObj.resource != null || resourceObj.rscUrl.length > 0">
                            <video class="videoPlayer" controls ref="video" v-show="resourceObj.type == 'video'">
                                <source ref="videoSource">
                            </video>

                            <v-img ref="image" :src="imgResource" class="imagePreview" v-show="resourceObj.type == 'Image'"></v-img>

                            <v-btn block depressed color="accent" style="text-transform:none;" @click="warningDialog = true">Remove resource</v-btn>
                        </div>
                        <div v-show="resourceObj.resource == null && resourceObj.rscUrl.length == 0" :class="'selectResource' + (resourceError.length > 0 ? ' resourceError' : '')" @click="showSelectDialog">
                            <v-icon size="48">mdi-plus</v-icon>
                            <div>Select {{ resourceObj.type.toLowerCase() }}</div>
                            <!-- <div v-if="resourceError.length > 0">{{ resourceError }}</div> -->
                        </div>

                        <v-textarea v-model="resourceObj.description" label="Description" outlined background-color="white"></v-textarea>

                        <span class="caption">Training steps</span>
                        <vue-editor v-model="resourceObj.instructions" :editorToolbar="editorToolbar" class="mb-5"></vue-editor>

                        <v-row no-gutters>
                            <v-col cols="10">
                                Show on specific days
                            </v-col>
                            <v-col cols="2" justify-end>
                                <v-switch v-model="resourceObj.onlyOn" class="mt-0"></v-switch>
                            </v-col>
                        </v-row>

                        <v-select v-model="resourceObj.availableOn" :items="availableOptions" item-text="text" item-value="value" 
                            label="Available on" outlined multiple chips background-color="white" v-show="resourceObj.onlyOn"></v-select>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="warningDialog" width="300">
            <v-card>
                <v-card-title>Warning</v-card-title>
                <v-card-text>
                    Are you sure you want to remove this resource?
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn depressed style="text-transform:none;" @click="warningDialog = false">Cancel</v-btn>
                    <v-btn depressed color="primary" style="text-transform:none;" @click="clearResources">Yes</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { ResourceType } from '../models'; // AvailableOptions, 
import { _st } from '../softech';
import { VueEditor } from 'vue2-editor';

export default {
    props: {
        value: { type: Array, default: () => { return []; } }
    },
    data() {
        return {
            resourceObj         : null,
            imgResource         : null,
            resources           : [],
            resourceDialog      : false,
            warningDialog       : false,
            resourcesTypes      : [ { text: 'Video', value: 'video' }, { text: 'Image', value: 'image' } ],
            availableOptions    : [ 
                { text: 'WeekDays', value: 'weekdays' },
                { text: 'Weekends', value: 'weekends' },
                { text: 'Monday', value: 'monday' },
                { text: 'Tuesday', value: 'tuesday' },
                { text: 'Wednesday', value: 'wednesday' },
                { text: 'Thursday', value: 'thursday' },
                { text: 'Friday', value: 'friday' },
                { text: 'Saturday', value: 'saturday' },
                { text: 'Sunday', value: 'sunday' } 
            ],
            errorMessage        : '',
            resourceError       : '',

            acceptImage         : 'image/png, image/jpeg',
            acceptVideo         : 'video/mp4, video/quicktime, video/x-ms-wmv, video/x-msvideo, video/x-matroska, video/mpeg',

            // FORM RULES
            nameRules           : [
                v => !!v || 'Name is required'
            ],

            editorToolbar       : [
                [{ font: [] }],
                [{ header: [false, 1, 2, 3, 4, 5, 6] }], 
                ["bold", "italic", "underline"], 
                [{ align: ["", "center", "right", "justify"]}],
                [{ list: "ordered" }, { list: "bullet" }],
                [{ script: "sub" }, { script: "super" }],
                [{ indent: "-1" }, { indent: "+1" }],
                ["link"],
                [{ color: [] }, { background: [] }],
            ],
        }
    },
    created() {
        this.clearResourceObj();
    },
    mounted() {
        this.init();
    },
    watch: {
        value() {
            this.init();
        }
    },
    methods: {
        init() {
            this.resources = _st.isNU( this.value ) ? [] : this.value;
        },
        removeResource(ix) {
            this.errorMessage = '';
            this.resources.splice(ix, 1);
            this.$emit('input', this.resources);
        },
        showSelectDialog() {
            this.$refs.picker.click();
        },
        addResource() {
            // validate inputs
            if( !this.$refs.form.validate() || ( this.resourceObj.resource == null && this.resourceObj.rscUrl.length == 0 ) ) {
                this.resourceError = this.resourceObj.resource == null ? 'You must select a resource' : '';
                return;
            }

            // add as a new item only when is new and is not being edit
            if( this.resourceObj.isNew && !this.resourceObj.isEdit ) {
                this.resourceObj.isEdit = false;
                this.resources.push( this.resourceObj );
            }
            else {
                let ix = this.resources.findIndex(r => {
                    // look for the original resource to be updated if is not new
                    if( !_st.isNU( this.resourceObj.id ) ) return r.id == this.resourceObj.id;

                    // if it is new, look for the resource by name 
                    // *** verificar si es la mejor opción buscarlo por nombre ***
                    return r.name == this.resourceObj.name;
                });

                if( ix > -1 ) {
                    this.resources[ix] = this.resourceObj;
                }
            }

            this.$emit( 'input', this.resources );

            this.closeForm();
        },
        closeForm() {
            this.resourceDialog = false;
            this.clearResourceObj();
            // this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.clearResources();
            this.resourceError = '';
        },
        clearResourceObj() {
            this.resourceObj = {
                id              : '',
                name            : '',
                type            : ResourceType.VIDEO.toLowerCase(),
                src             : { key: '', bucket: '', region: '' },
                description     : '',
                instructions    : '',
                onlyOn          : false,
                availableOn     : [],
                resource        : null,
                isNew           : true,
                isEdit          : false,
                rscUrl          : ''
            };
        },
        getResourceSrc() {
            // get resource src from the S3 bucket
            return '';
        },
        updateResource() {
            this.resourceObj.resource = this.$refs.picker.files[0];
            this.loadResource();
        },
        loadResource() {
            // if it is a video
            if( this.resourceObj.type == ResourceType.VIDEO.toLowerCase() ) {
                let src = _st.isNU( this.resourceObj.resource ) && this.resourceObj.rscUrl.length > 0 ? this.resourceObj.rscUrl : URL.createObjectURL( this.resourceObj.resource );
                
                if( _st.isNU( this.$refs.videoSource ) ) {
                    setTimeout( () => {
                        this.$refs.videoSource.src = src;
                        this.$refs.video.load();
                    }, 500 );
                    return;
                }

                this.$refs.videoSource.src = src;
                this.$refs.video.load();
                return;
            }

            // continue if it is an image
            if( _st.isNU( this.resourceObj.resource ) && this.resourceObj.rscUrl.length > 0 ) {
                this.imgResource = this.resourceObj.rscUrl;
            }

            var reader = new FileReader();
            reader.onloadend = () => {
                this.imgResource = reader.result;
            }
            reader.readAsDataURL( this.resourceObj.resource );
        },
        clearResources() {
            this.resourceObj.resource = null; 
            this.resourceObj.rscUrl = '';
            this.warningDialog = false;

            // destroy video resource
            this.$refs.video.pause();
            this.$refs.videoSource.removeAttribute('src');
            this.$refs.video.load();

            // destroy image resource
            this.imgResource = '';            
        },
        editResource( rsc ) {
            this.resourceDialog             = true;

            this.resourceObj.id             = rsc.id;
            this.resourceObj.name           = rsc.name;
            this.resourceObj.type           = rsc.type;
            this.resourceObj.src            = rsc.src;
            this.resourceObj.description    = rsc.description;
            this.resourceObj.instructions   = rsc.instructions;
            this.resourceObj.onlyOn         = rsc.onlyOn;
            this.resourceObj.availableOn    = rsc.availableOn;
            this.resourceObj.resource       = rsc.resource; // rsc.id.length == 0 ? rsc.resource : this.getResourceSrc();
            this.resourceObj.isNew          = rsc.id.length == 0 ? true : false;
            this.resourceObj.isEdit         = true;
            this.resourceObj.rscUrl         = rsc.rscUrl;
            this.loadResource();
        }
    },
    components: {
        VueEditor
    },
}
</script>

<style lang="scss" scoped>
.selectResource {
    width: 100%;
    height: 150px;
    border-radius: 8px;
    background-color: #cdcdcd;
    text-align: center;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}
.selectResource.resourceError {
    background-color: #EF9A9A;
}
.hidden {
    display: none;
}
.resourceWrapper {
    margin-bottom: 20px;
}
.videoPlayer, 
.imagePreview {
    width: 100vw;
    // margin-left: -24px;
    max-height: 300px;
}
</style>